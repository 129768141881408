import { useState, useCallback, useEffect, useMemo } from 'react';
import { ApolloQueryResult, useApolloClient } from '@apollo/client';

import {
  GET_ALL_DOCUMENT_NAMES,
  GetAllDocumentNamesResponse,
  GetAllDocumentNamesParams
} from '~app/apollo/queries/getAllDocumentNames';
import {
  GET_UPLOADABLE_DOCUMENTS,
  GetUploadableDocumentsResponse,
  GetUploadableDocumentsParams
} from '~app/apollo/queries/getUploadableDocuments';
import {
  GetDocumentLinkParams,
  GetDocumentLinkResponse,
  GET_DOCUMENT_LINK
} from '~app/apollo/queries/getDocumentLink';
import getRuntimeConfig from '~root/src/config';
import {
  DocumentData as ImportedDocumentData,
  DocumentSearchType,
  SearchDocumentsRequest,
  SearchDocumentsResponse,
  SEARCH_DOCUMENTS
} from '~app/apollo/queries/searchDocuments';
import { GetBatchResponse, GetBatchRequest, GET_BATCH } from '../apollo/queries/getBatch';
import { Document, Handover } from '~apollo/queries/getHandoverExtended';

const DocumentTypesConst = [
  'AuthorisationForm',
  'BankDocument',
  'BankInvoicePreHandover',
  'CertificateOfConformity',
  'CertificateOfRegistration',
  'CTPInsurance',
  'DeliveryAcceptance',
  'DigitalReceiptAdditionalDelivery',
  'DriversLicense',
  'DriversLicenseFront',
  'DriversLicenseBack',
  'EarlyInvoice',
  'ForeignersWorkPermit',
  'FSPInvoiceBeforeHandover',
  'InsuranceCertificate',
  'PictureOfManufacturerPlate',
  'PreviousCarRegistrationCertificate',
  'RegistrationCertificate',
  'RegistrationReport',
  'TestReport',
  'ThirdPartyDeliveryAcceptance',
  'TradeInPaymentReceipt',
  'TradeInCarInspectionReport',
  'TradeInProcessGuide',
  'VATExemptionFormDiplo',
  'Vignette705',
  'OtherDocumentType',
  'PersonalIdFrontPrimary',
  'PhotoExterior',
  'PhotoInterior',
  'PhotoDamage',
  'PhotoEquipment',
  'FleetPurchaseOrder',
  'ProofOfExport',
  'ProofOfAddress',
  'LetterOfAuthority',
  'VehicleRegistrationForm',
  'RegistrationConcessionDocument',
  'B2BSalesContract',
  'B2CSalesContract',
  'ExternalWinterWheelDeliveryForm',
  'CertificateOfVehicleRegistration',
  'MUASigned',
  'MUAUnsigned',
  'PickUpConfirmation',
  'TransportationAgreement',
  'BillAndHoldAgreement',
  'QualityControl',
  'DealerRegistrationCertificate',
  'TaxInvoice'
] as const;

const documentTypesWhereNotOnlyLatestMatters = [
  'DigitalReceiptAdditionalDelivery',
  'PhotoInterior',
  'PhotoExterior',
  'PhotoDamage',
  'PhotoEquipment',
  'DriversLicenseFront',
  'DriversLicenseBack'
];

const documentSubTypesWhereOnlyLatestMatters = [
  'StateOfCharge',
  'Odometer',
  'Dashboard',
  'Front',
  'NearSide',
  'OffSide',
  'Rear',
  'Boot',
  'CenterConsole',
  'FrontCab',
  'RearCab'
];

export type DocumentType = (typeof DocumentTypesConst)[number];
export type DocumentData = ImportedDocumentData;

export interface DocHubDocument {
  type: DocumentType;
  translatedType: string;
  subType: string;
  id: string;
  createdDate?: string | null;
  url?: string;
}

const useDocuments = () => {
  const client = useApolloClient();

  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [isLoadingDocumentsDone, setIsLoadingDocumentsDone] = useState(false);
  const [documents, setDocuments] = useState<Array<DocumentData>>([]);

  const [isLoadingDocumentNameTranslations, setIsLoadingDocumentNameTranslations] = useState(false);
  const [documentNameTranslations, setDocumentNameTranslations] = useState<
    Array<{ systemName: string; translatedName: string; englishName: string }>
  >([]);

  const [isLoadingUploadableDocuments, setIsLoadingUploadableDocuments] = useState(false);
  const [uploadableDocuments, setUploadableDocuments] = useState<Array<DocumentType>>([]);

  const [documentCurrentlyBeingFetched, setDocumentCurrentlyBeingFetched] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const isChina = getRuntimeConfig().build.deployRegion === 'china';

  useEffect(() => {
    setIsLoading(
      isLoadingDocuments || isLoadingDocumentNameTranslations || isLoadingUploadableDocuments
    );
  }, [isLoadingDocuments, isLoadingDocumentNameTranslations, isLoadingUploadableDocuments]);

  const fetchDocumentLink = useCallback(
    async (docId: string, download: boolean, docName?: string) => {
      setIsLoading(true);
      try {
        const { data, error } = await client.query<GetDocumentLinkResponse, GetDocumentLinkParams>({
          query: GET_DOCUMENT_LINK,
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              id: docId,
              contentDisposition: download ? 'Attachment' : 'Inline',
              downloadFileName: docName ? docName.replace(',', '') : undefined
            }
          }
        });
        if (typeof error === 'undefined' && typeof data !== 'undefined') {
          setIsLoading(false);
          return data.documentLink;
        }
      } catch (error) {
        setIsLoading(false);
        // Todo: do nothing
        console.dir(error);
        return undefined;
      }
    },
    [client]
  );

  const fetchAllDocumentLinks = useCallback(
    async (documents: Array<DocHubDocument>, handover: Handover) => {
      setIsLoading(true);
      const linksQueries = documents.map(async doc => {
        try {
          const docLinkResult = await client.query<GetDocumentLinkResponse, GetDocumentLinkParams>({
            query: GET_DOCUMENT_LINK,
            fetchPolicy: 'no-cache',
            variables: {
              input: {
                id: doc.id,
                contentDisposition: 'Inline',
                downloadFileName: `${doc.type}_${handover.vin}_${handover.customer?.lastName}`
              }
            }
          });
          return {
            ...doc,
            url: docLinkResult.data.documentLink
          };
        } catch (err) {
          console.error('Failed to fetch documentLink for docId:', doc.id);
          console.error(err);
          return { ...doc, url: '' };
        }
      });

      let allDocsWithLinks: Array<DocHubDocument> = [];

      await Promise.all(linksQueries).then(result => {
        allDocsWithLinks = result;
      });

      setIsLoading(false);
      return allDocsWithLinks;
    },
    [client]
  );

  const fetchDocuments = useCallback(
    async (orderNumber: string, batchId?: string) => {
      if (isChina) {
        setIsLoadingDocumentsDone(true);
      } else {
        setIsLoadingDocuments(true);
        setIsLoadingDocumentsDone(false);
        let batchDocs: Array<Document> = [];
        const promises = [];

        promises.push(
          client.query<SearchDocumentsResponse, SearchDocumentsRequest>({
            query: SEARCH_DOCUMENTS,
            fetchPolicy: 'no-cache',
            variables: {
              input: { searchVal: orderNumber, searchType: DocumentSearchType.ORDER_NUMBER }
            }
          })
        );
        if (batchId) {
          promises.push(
            client.query<GetBatchResponse, GetBatchRequest>({
              query: GET_BATCH,
              fetchPolicy: 'no-cache',
              variables: { input: { id: batchId } }
            })
          );
        }
        return Promise.all(promises).then(promiseRes => {
          const searchRes = promiseRes[0] as ApolloQueryResult<SearchDocumentsResponse>;
          if (promiseRes.length > 1) {
            const batchRes = promiseRes[1] as ApolloQueryResult<GetBatchResponse>;
            batchDocs = batchRes.data.response.documents ?? [];
          }
          const batchDocumentsData = batchDocs.map(doc => ({
            id: doc.id,
            documentType: doc.documentType,
            documentId: doc.documentId ?? '',
            dateCreated: doc.startDate ?? '',
            size: 0,
            contentType: 'pdf'
          }));

          const docs: Array<DocumentData> = [...searchRes.data.documents, ...batchDocumentsData];
          const res = docs.reduce((filteredDocs: Array<DocumentData>, doc) => {
            const existingDoc = filteredDocs.find(x => x.documentType === doc.documentType);
            if (
              existingDoc === undefined ||
              documentTypesWhereNotOnlyLatestMatters.includes(doc.documentType)
            ) {
              if (documentSubTypesWhereOnlyLatestMatters.includes(doc.subType ?? '')) {
                //only latest matters, overwrite if newer than doc that already exists, otherwise ignore
                const existingSubTypeDoc = filteredDocs.find(
                  x => x.documentType === doc.documentType && x.subType === doc.subType
                );
                const existingDocIndex = filteredDocs.findIndex(
                  x => x.documentType === doc.documentType && x.subType === doc.subType
                );
                if (existingSubTypeDoc) {
                  if (doc.dateCreated > existingSubTypeDoc.dateCreated) {
                    //there was a newer doc with this subtype, overwrite
                    filteredDocs[existingDocIndex] = doc;
                  }
                } else {
                  //the subType doc doesnt exist, so we add it
                  filteredDocs.push(doc);
                }
              } else {
                //the type doc doesnt exist, so we add it
                filteredDocs.push(doc);
              }
            } else if (doc.dateCreated > existingDoc.dateCreated) {
              //there was a newer doc with this type, overwrite
              const existingDocIndex = filteredDocs.findIndex(
                x => x.documentType === existingDoc.documentType
              );
              filteredDocs[existingDocIndex] = doc;
            }
            return filteredDocs;
          }, []);
          setDocuments(
            res.sort(
              (a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
            )
          );
          setIsLoadingDocuments(false);
          setIsLoadingDocumentsDone(true);
          return res.sort(
            (a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
          );
        });
      }
    },
    [client, isChina]
  );

  const fetchDocumentNameTranslations = useCallback(
    async (language: string) => {
      setIsLoadingDocumentNameTranslations(true);

      const { data, error } = await client.query<
        GetAllDocumentNamesResponse,
        GetAllDocumentNamesParams
      >({
        query: GET_ALL_DOCUMENT_NAMES,
        variables: {
          language: language
        },
        context: { dato: true }
      });

      if (typeof error === 'undefined' && typeof data !== 'undefined' && data.names.length > 0) {
        const nativeNames = data.names.map(x => ({
          systemName: x.systemName,
          translatedName: x.humanReadableName
        }));
        const englishNames = data.fallbackNames.map(x => ({
          systemName: x.systemName,
          englishName: x.humanReadableName
        }));
        setDocumentNameTranslations(
          englishNames.map(x => ({
            systemName: x.systemName,
            translatedName:
              nativeNames.find(y => y.systemName === x.systemName)?.translatedName ?? '',
            englishName: x.englishName
          }))
        );
      }

      setIsLoadingDocumentNameTranslations(false);
    },
    [client]
  );

  const fetchUploadableDocuments = useCallback(
    async (marketId: string) => {
      setIsLoadingUploadableDocuments(true);
      const { data, error } = await client.query<
        GetUploadableDocumentsResponse,
        GetUploadableDocumentsParams
      >({
        query: GET_UPLOADABLE_DOCUMENTS,
        variables: {
          market: marketId
        },
        context: { dato: true }
      });

      if (
        typeof error === 'undefined' &&
        typeof data !== 'undefined' &&
        data.handoverUploadableDocument.documents.length > 0
      ) {
        setUploadableDocuments(data.handoverUploadableDocument.documents.map(x => x.systemName));
      }
      setIsLoadingUploadableDocuments(false);
    },
    [client]
  );

  const fetchDocumentFile = useCallback(
    async (docId: string, download: boolean, options?: { docName?: string }) => {
      setDocumentCurrentlyBeingFetched(docId);

      const link = await fetchDocumentLink(docId, download, options?.docName);
      if (link !== undefined) {
        if (download) {
          const element = document.createElement('a');
          element.download = options?.docName ?? '';
          element.href = link;
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        } else {
          window.open(link, '_blank');
        }
      }

      setDocumentCurrentlyBeingFetched('');
    },
    [fetchDocumentLink]
  );

  const fleetPurchaseOrderDocument = useMemo(() => {
    return documents.find(d => d.documentType === 'FleetPurchaseOrder');
  }, [documents]);

  const isFleetPurchaseOrderAvailable = useMemo(() => {
    if (uploadableDocuments && uploadableDocuments.find(d => d === 'FleetPurchaseOrder')) {
      return true;
    }
    return false;
  }, [uploadableDocuments]);

  return {
    documents,
    documentNameTranslations,
    uploadableDocuments,
    fleetPurchaseOrderDocument,
    isFleetPurchaseOrderAvailable,
    documentCurrentlyBeingFetched,
    fetchDocuments,
    fetchDocumentNameTranslations,
    fetchUploadableDocuments,
    fetchDocumentLink,
    fetchAllDocumentLinks,
    fetchDocumentFile,
    isLoading,
    isLoadingDocuments,
    isLoadingDocumentsDone,
    isLoadingDocumentNameTranslations,
    isLoadingUploadableDocuments
  };
};

export default useDocuments;
